import { useCallback, useEffect, useRef, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import classNames from 'classnames';
import {
  ShoppingCart as ShoppingCartIcon,
  X as XIcon,
  Cpu as ControllerIcon,
  GitPullRequest as RobotsIcon,
  Wind as PneumaticIcon,
  Camera as VisionIcon,
  Paperclip as AccessoriesIcon,
  MousePointer as SoftwareIcon
} from 'react-feather';
import { useTranslation } from 'next-i18next';
import RobotBundleProduct from '~/components/robot-bundle-product';
import RobotBundlePrefilled from '~/components/robot-bundle-prefilled';
import RobotBundleSection from '~/components/robot-bundle-section';
import RobotBundleCartSummary from '~/components/robot-bundle-cart-summary';
import RobotBundlePlaceholder from '~/components/robot-bundle-placeholder';
import RobotBundleShareButton from '~/components/robot-bundle-share-button';
import { EndEffectorIcon, ServiceIcon } from '~/components/rbtx-icons';
import { RequestApi } from '~/lib/api-client';
import trackEvent from '~/lib/analytics.service';
import styles from './styles.module.scss';
import type { Bundle } from '~/types';
import useApi from '~/lib/hooks/use-api';
import { SafetyIcon } from '../rbtx-icons/safety';
import { MobileRobotIcon } from '../rbtx-icons/mobile-robot';
import { ProfilesIcon } from '../rbtx-icons/profiles';

export const iconMap = {
  control_system: <ControllerIcon size={36} />,
  complete_controller: <ControllerIcon size={36} />,
  motion_controller: <ControllerIcon size={36} />,
  motor_controller: <ControllerIcon size={36} />,
  robots: <RobotsIcon size={36} />,
  pneumatics: <PneumaticIcon size={36} />,
  vision: <VisionIcon size={36} />,
  accessories: <AccessoriesIcon size={36} />,
  safety: <SafetyIcon size={36} />,
  end_effectors: <EndEffectorIcon size={36} />,
  service: <ServiceIcon size={36} />,
  software: <SoftwareIcon size={36} />,
  mobile_robots: <MobileRobotIcon size={36} />,
  profiles_more: <ProfilesIcon size={36} />
};

type Props = {
  hasProducts?: boolean;
  onBundleClosed?: () => void;
  showBundle?: boolean;
  onBundleUpdate?: (bundle: Bundle, checkForCompability?: boolean) => void;
  onBundleLoaded?: (bundle: Bundle) => void;
};

const RobotBundle = ({ onBundleClosed, showBundle, onBundleUpdate, onBundleLoaded, ...props }: Props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean | undefined>(showBundle);
  const [showShareLink, setShowShareLink] = useState(props.hasProducts);
  const [hasProducts, setHasProducts] = useState<boolean>();
  const [bundle, setBundle] = useState<Bundle>({ sections: [] });
  const overlayElementRef = useRef<HTMLDivElement>(null);
  const [scrollBarWidth, setsScrollBarWidth] = useState(15);
  const [robotBundleBtn, setRobotBundleBtn] = useState<HTMLButtonElement | null>(null);
  const loadBundleCancelSourceRef = useRef<CancelTokenSource>();
  const [backgroundClass, setBackgroundClass] = useState<string>(styles['robot-bundle__border']);

  const removeScrollBody = useCallback(() => {
    const { body } = document;
    document.body.style.paddingRight = '0';
    body.classList.remove('--disableScroll');

    if (robotBundleBtn) {
      robotBundleBtn.style.paddingRight = `0px`;
    }
  }, [robotBundleBtn]);

  const onClose = () => {
    trackEvent('MyRobotBundle', 'close');
    setShow(false);
    onBundleClosed?.();
    removeScrollBody();
  };

  const onOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const overlayEl = overlayElementRef.current;
    if (!overlayEl || !(e.target instanceof Element)) return;
    if (overlayEl?.getAttribute('id') === e.target.getAttribute('id')) {
      e.stopPropagation();
      onClose();
    }
  };

  const addScrollbarGap = useCallback(() => {
    const { body } = document;
    body.classList.add('--disableScroll');
    body.style.paddingRight = `${scrollBarWidth}px`;
    if (robotBundleBtn) {
      const rect = robotBundleBtn.getBoundingClientRect();
      if (document.documentElement.clientWidth - (rect.left + rect.width) < scrollBarWidth) {
        robotBundleBtn.style.paddingRight = `${scrollBarWidth}px`;
      }
    }
  }, [robotBundleBtn, scrollBarWidth]);

  const handleOnShareLinkCreated = (shareLink: string) => {
    setShowShareLink(true);
    trackEvent('MyRobotShareLinkCreated', shareLink);
  };

  const handleBundleUpdate = (newBundle: Bundle, checkForCompability?: boolean) => {
    setBundle(newBundle);
    setHasProducts(newBundle.sections.some((s) => s.products.length > 0));
    setShowShareLink(false);
    setShow(true);

    if (!hasProducts || hasProducts === undefined) {
      setBackgroundClass(styles['robot-bundle__border']);
    }
    onBundleUpdate?.(newBundle, checkForCompability);
  };

  const loadBundle = useCallback(async () => {
    loadBundleCancelSourceRef.current = axios.CancelToken.source();
    try {
      const data = await RequestApi<Bundle>({
        method: 'get',
        url: `/bundle`,
        cancelTokenSource: loadBundleCancelSourceRef.current
      });
      setBundle(data);
      setHasProducts(data.sections.some((s) => s.products.length > 0));
      setShowShareLink(false);
      setShow(true);

      // delay scrollbar gap to align with the animation
      setTimeout(() => {
        addScrollbarGap();
      }, 200);

      if (!hasProducts || hasProducts === undefined) {
        setBackgroundClass(styles['robot-bundle__border']);
      } else {
        setBackgroundClass(classNames(styles['robot-bundle__border'], styles.industry));
      }
      onBundleLoaded?.(data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.info('Request canceled', (error as any).message);
      } else {
        throw error;
      }
    }
  }, [hasProducts, onBundleLoaded]);

  const handleQuoteClick = () => {
    trackEvent('MyRobotBundle', 'ClickCheckout');
  };

  useEffect(() => {
    setsScrollBarWidth(window.innerWidth - document.documentElement.clientWidth);
    setRobotBundleBtn(document.querySelector<HTMLButtonElement>('.my-robot-container')!);
    return () => {
      removeScrollBody();
      if (loadBundleCancelSourceRef.current) {
        loadBundleCancelSourceRef.current.cancel('Load robot bundle canceled!');
      }
    };
  }, [removeScrollBody]);

  useEffect(() => {
    if (showBundle) {
      loadBundle();
    }
  }, [showBundle]);

  const [resetCartState, resetCartRequest] = useApi();
  const deleteAllProducts = () => {
    if (resetCartState.isLoading) {
      return;
    }

    resetCartRequest({
      method: 'delete',
      url: '/bundle'
    });
  };

  useEffect(() => {
    if (resetCartState.success) {
      trackEvent('MyRobotBundle', 'ResetCart');
      handleBundleUpdate(resetCartState.data, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetCartState]);

  const products = bundle.sections.map((bundleSection, i) => {
    if (bundleSection.products.length > 0) {
      const addPlaceholders = bundleSection.multipleDifferentProductsInCart;

      let placeholder = null;

      // placeholder = addMore button
      if (addPlaceholders) {
        placeholder = (
          <RobotBundlePlaceholder
            bundleSection={bundleSection}
            canAddMultipleProducts={bundleSection.multipleDifferentProductsInCart}
            onClick={onClose}
            icon={iconMap[bundleSection.key as keyof typeof iconMap]}
          />
        );
      }

      return (
        <RobotBundleSection bundleSection={bundleSection} key={i.toString()}>
          {bundleSection.products.map((product, index) => {
            return (
              <RobotBundleProduct
                key={index.toString()}
                bundleSection={bundleSection}
                product={product}
                onBundleUpdate={handleBundleUpdate}
                onClick={onClose}
              />
            );
          })}
          {placeholder}
        </RobotBundleSection>
      );
    }
    if (
      bundleSection.hasIntegratedController ||
      bundleSection.hasCompleteController ||
      bundleSection.hasIntegratedMotorController
    ) {
      let text = '';

      if (bundleSection.hasIntegratedController) {
        text = t('myRobotBundleIntegratedControllerHint');
      } else if (bundleSection.hasCompleteController) {
        text = t('myRobotBundleCompleteControllerHint');
      } else if (bundleSection.hasIntegratedMotorController) {
        text = t('myRobotBundleIntegratedMotorControllerHint');
      }

      return (
        <RobotBundleSection bundleSection={bundleSection} key={i.toString()}>
          <RobotBundlePrefilled text={text} icon={iconMap[bundleSection.key as keyof typeof iconMap]} />
        </RobotBundleSection>
      );
    }
    if (bundleSection.pneumaticNotRequired) {
      return (
        <RobotBundleSection bundleSection={bundleSection} key={i.toString()}>
          <RobotBundlePrefilled
            text={t('myRobotBundlePneumaticNotRequiredHint')}
            icon={iconMap[bundleSection.key as keyof typeof iconMap]}
          />
        </RobotBundleSection>
      );
    }

    const placeholder = (
      <RobotBundlePlaceholder
        bundleSection={bundleSection}
        onClick={onClose}
        icon={iconMap[bundleSection.key as keyof typeof iconMap]}
      />
    );

    return (
      <RobotBundleSection bundleSection={bundleSection} key={i.toString()}>
        {placeholder}
      </RobotBundleSection>
    );
  });
  return (
    <div
      id="robot-bundle-overlay"
      className={classNames(styles['robot-bundle-overlay'], {
        [classNames(styles['animated'], styles['fadeInLeft'])]: show === true,
        [classNames(styles['animated'], styles['fadeInRight'])]: show === false
      })}
      role="presentation"
      onClick={onOverlayClick}
      ref={overlayElementRef}
    >
      <div
        key="bundle"
        className={classNames(styles['robot-bundle'], 'ml-auto')}
        data-testid="robot-bundle-overlay"
      >
        <div className={backgroundClass}>
          <header>
            <b className={classNames(styles['robot-bundle__title'], 'flex items-center')}>
              <ShoppingCartIcon size={24} />
              <span>{t('myRobotBundleHeadline')}</span>
            </b>
            {!hasProducts ? (
              <>
                <b className={styles['robot-bundle__headline-empty']}>
                  {t('myRobotBundleSelectionModeHeadlineEmpty')}
                </b>
                <p className={styles['robot-bundle__subheadline-empty']}>
                  {t('myRobotBundleSelectionModeSubHeadLineEmpty')}
                </p>
              </>
            ) : (
              <>
                <RobotBundleCartSummary onClick={handleQuoteClick} totalPrice={bundle.totalPrice ?? -1} />
                <RobotBundleShareButton
                  onShareLinkCreated={handleOnShareLinkCreated}
                  showLink={showShareLink}
                />
              </>
            )}
            <button
              type="button"
              className={styles['robot-bundle__close']}
              tabIndex={0}
              onClick={onClose}
              aria-label="Close"
            >
              <XIcon size={30} />
            </button>
          </header>
          <div className={styles['robot-bundle__body']}>{products}</div>
          <footer className="pt-4">
            {hasProducts && (
              <RobotBundleCartSummary
                onClick={handleQuoteClick}
                totalPrice={bundle.totalPrice ?? -1}
                onClear={deleteAllProducts}
                onClearState={resetCartState}
              />
            )}
          </footer>
        </div>
      </div>
    </div>
  );
};

export default RobotBundle;
