import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

const RobotBundleSection = ({ bundleSection, children }) => {
  if (!bundleSection.name) {
    return (
      <div
        className={classNames(styles['robot-bundle-section'])}
        data-testid={`robot-bundle-section-${bundleSection.key}`}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={classNames(styles['robot-bundle-section'])}
      data-testid={`robot-bundle-section-${bundleSection.key}`}
    >
      <span className={classNames(styles['robot-bundle-section__name'], 'font-bold text-lg uppercase')}>
        {bundleSection.name}
      </span>
      {children}
    </div>
  );
};

RobotBundleSection.defaultProps = {
  children: null
};

RobotBundleSection.propTypes = {
  bundleSection: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default RobotBundleSection;
