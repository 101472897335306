import type React from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import trackEvent from '~/lib/analytics.service';
import PathMap from '~/shared/pathMap';

import styles from './styles.module.scss';
import type { BundleSection } from '~/types';

type Props = {
  bundleSection: BundleSection;
  canAddMultipleProducts?: boolean;
  onClick: () => void;
  icon: React.ReactNode;
};

const RobotBundlePlaceholder = ({ bundleSection, canAddMultipleProducts, onClick, icon }: Props) => {
  const { t } = useTranslation();
  const handlePlaceholderClick = () => {
    trackEvent('MyRobotBundle', 'AddCompatibleProduct', bundleSection.key);
    onClick();
  };

  return (
    <Link
      href={`${PathMap.industry.as([bundleSection.slug])}`}
      role="button"
      tabIndex={0}
      onClick={handlePlaceholderClick}
      className={classNames(styles['robot-bundle-placeholder'], 'flex', {
        [styles['robot-bundle-placeholder__multiple']]: canAddMultipleProducts,
        [styles['robot-bundle-placeholder__group']]: bundleSection.groupKey !== '',
        [styles['robot-bundle-placeholder']]: !canAddMultipleProducts
      })}
    >
      <span className="flex items-center">
        {icon}
        <b className={classNames(styles['robot-bundle-placeholder__label'], 'pl-3')}>
          {canAddMultipleProducts
            ? t(`myRobotBundleAddMoreBtn.${bundleSection.key}`)
            : t(`myRobotBundleAddBtn.${bundleSection.key}`)}
        </b>
      </span>
    </Link>
  );
};

export default RobotBundlePlaceholder;
