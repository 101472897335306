import React, { useEffect, useState, useMemo, ChangeEvent } from 'react';
import { Camera as CameraIcon, Trash as TrashIcon } from 'react-feather';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import trackEvent from '~/lib/analytics.service';
import useApi from '~/lib/hooks/use-api';
import PathMap from '~/shared/pathMap';
import LoadingSymbol from '~/components/loading-symbol';

import styles from './styles.module.scss';
import type { Bundle, BundleSection, Product } from '~/types';

type Props = {
  bundleSection: BundleSection;
  product: Product;
  onBundleUpdate: (bundle: Bundle, checkForCompability?: boolean) => void;
  onClick: () => void;
};

const RobotBundleProduct = ({ bundleSection, product, onBundleUpdate, onClick }: Props) => {
  const { t } = useTranslation();
  const Router = useRouter();

  const productLink = product.variantSlug
    ? PathMap.productVariant.as([bundleSection.slug, product.slug, product.variantSlug])
    : PathMap.product.as([bundleSection.slug, product.slug]);

  const [productQuantity, setProductQuantity] = useState(product.quantity);
  const [deleteState, deleteProductRequest] = useApi();
  const [updateState, updateProductRequest] = useApi();

  const refreshListOnChange = () => {
    const categorySlug = Router.query.categorySlug ? Router.query.categorySlug : '';
    if (Router.pathname === PathMap.components.pathname) {
      Router.push(
        {
          pathname: PathMap.components.pathname,
          query: {
            categorySlug
          }
        },
        PathMap.components.as([categorySlug as string])
      );
    }
  };

  useEffect(() => {
    if (deleteState.success) {
      trackEvent('MyRobotBundle', 'RemoveProduct', product.sku);
      trackEvent('MyRobotBundle', 'RemoveProduct', bundleSection.key);
      onBundleUpdate(deleteState.data, true);
      // only when the user is on the component list page
      // we will refetch the component list because the result based on the bundle
      refreshListOnChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteState]);

  useEffect(() => {
    if (updateState.success) {
      trackEvent('MyRobotBundle', 'ChangeQuantity', product.sku);
      trackEvent('MyRobotBundle', 'ChangeQuantity', String(productQuantity));

      // only update compatibility when an item has been removed
      onBundleUpdate(updateState.data, productQuantity === 0);
      // only when the user is on the component list page
      // we will refetch the component list because the result based on the bundle
      refreshListOnChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  const handleViewDetailsClick = (sku: string) => {
    trackEvent('MyRobotBundle', 'ClickViewDetails', sku);
    onClick();
  };

  const deleteBundleProduct = () => {
    if (deleteState.isLoading) {
      return;
    }
    const productId = product.lineItemId;
    deleteProductRequest({
      method: 'delete',
      url: `/bundle/products`,
      data: {
        productId
      }
    });
  };

  const generateQuantityOptions = (count: number) => {
    const options = [];
    for (let i = 0; i <= count; i += 1) {
      options.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return options;
  };

  const quantityOptions = useMemo(
    () => generateQuantityOptions(product.maxQuantity || 10),
    [product.maxQuantity]
  );

  const updateBundleProduct = (event: ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    const quantity = parseInt(event.target.value, 10);
    if (updateState.isLoading || quantity === product.quantity) {
      return;
    }
    setProductQuantity(quantity);
    const productId = product.lineItemId;
    updateProductRequest({
      method: 'put',
      url: `/bundle/products/${productId}`,
      data: {
        quantity
      }
    });
  };

  return (
    <>
      <div
        className={classNames(
          'flex',
          deleteState.isLoading || updateState.isLoading
            ? classNames(styles['robot-bundle-product'], styles['deleting'])
            : styles['robot-bundle-product']
        )}
      >
        <div
          className={classNames(
            styles['robot-bundle-product__left-box'],
            'flex flex-shrink-0 items-center justify-center'
          )}
        >
          <div
            role="button"
            tabIndex={0}
            className={classNames(styles['robot-bundle-product__thumbnail'], 'text-center')}
          >
            {product.imageUrl ? (
              <Link
                href={productLink}
                role="link"
                tabIndex={0}
                onClick={onClick}
                title={`${product.name} ${product.variantLabel ? `(${product.variantLabel})` : ''}`}
              >
                <img className="mx-auto object-contain p-2" alt={product.name} src={product.imageUrl} />
              </Link>
            ) : (
              <span className={styles['placeholder']}>
                <CameraIcon size={32} />
              </span>
            )}
          </div>
        </div>
        <div className={classNames(styles['robot-bundle-product__right-box'], 'flex flex-col truncate py-2')}>
          <Link
            href={productLink}
            onClick={() => handleViewDetailsClick(product.sku)}
            role="button"
            tabIndex={-1}
            className="btn btn-link btn-sm whitespace-nowrap flex items-center"
          >
            <b className="text-truncate" title={product.name}>
              {product.name}
            </b>
          </Link>
          {product.variantLabel && (
            <span title={product.variantLabel} className={styles.variant}>
              {product.variantLabel}
            </span>
          )}
          <span title={product.vendorCategory.name} className={classNames(styles['vendor'], 'truncate')}>
            {product.vendorCategory.name}
          </span>
          <div
            className={classNames(
              styles['robot-bundle-product__actions'],
              'pt-2 flex mt-auto justify-between'
            )}
          >
            {bundleSection.quantityProductSelector &&
              (bundleSection.multipleDifferentProductsInCart || product.quantity > 1) && (
                <div
                  className={classNames(
                    styles['robot-bundle-product__actions__selector'],
                    'whitespace-nowrap flex items-center'
                  )}
                >
                  <div className={classNames(styles['robot-bundle-product__actions__selector__label'])}>
                    {t('quantity')}
                  </div>
                  <select
                    onChange={updateBundleProduct}
                    onBlur={updateBundleProduct}
                    disabled={
                      !bundleSection.multipleDifferentProductsInCart ||
                      deleteState.isLoading ||
                      updateState.isLoading
                    }
                    data-testid="bundle-thumb-quantity-selector"
                    defaultValue={product.quantity}
                  >
                    {quantityOptions}
                  </select>
                </div>
              )}
            <a
              className={classNames(
                styles['robot-bundle-product__actions__delete'],
                'btn btn-link btn-sm whitespace-nowrap flex items-center ml-auto'
              )}
              href="#"
              onClick={deleteBundleProduct}
            >
              {deleteState.isLoading ? <LoadingSymbol size="22px" /> : <TrashIcon size={22} />}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

RobotBundleProduct.propTypes = {
  bundleSection: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  onBundleUpdate: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default RobotBundleProduct;
