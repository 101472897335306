import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

const LoadingSymbol = ({ size, className }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames('loading-symbol spinner-border', className)}
      role="status"
      style={{ width: size, height: size }}
    >
      <span className="sr-only">{t('loadingText')}</span>
    </div>
  );
};

LoadingSymbol.defaultProps = {
  size: '22px',
  className: ''
};

LoadingSymbol.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string
};

export default LoadingSymbol;
