import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useCopyToClipboard } from 'react-use';
import { useTranslation } from 'next-i18next';
import styles from './styles.module.scss';
import Skeleton from 'react-loading-skeleton';
import { useMutation } from '@tanstack/react-query';
import { RequestApi } from '~/lib/api-client';

function getShareLinkUrl(path: string) {
  const url = new URL(window.location.href);
  url.pathname = path;
  return url.toString();
}

type Props = {
  showLink?: boolean;
  theme?: 'dark' | 'light';
  onShareLinkCopied?: () => void;
  onShareLinkCreated: (url: string) => void;
};

const RobotBundleShareButton = ({
  showLink,
  onShareLinkCreated,
  onShareLinkCopied,
  theme = 'light'
}: Props) => {
  const { t } = useTranslation();

  const shareLinkMutation = useMutation(
    async () => {
      return RequestApi<{ url: string }>({
        method: 'post',
        url: `/share/bundle`
      });
    },
    {
      onSuccess(data) {
        onShareLinkCreated(getShareLinkUrl(data.url));
      }
    }
  );
  const [, copyToClipboard] = useCopyToClipboard();
  const [linkCopied, setLinkCopied] = useState(false);

  const shareLinkUrl = shareLinkMutation.data?.url;

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (linkCopied) {
      timer = setTimeout(() => setLinkCopied(false), 3000);
    }
    return () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
    };
  }, [linkCopied]);

  return (
    <div className={classNames(styles['robot-bundle-share-button'], 'mt-3')}>
      {showLink ? (
        <div className="flex h-12">
          <input
            type="text"
            className={classNames(
              styles['robot-bundle-share-button__input'],
              'form-control',
              styles[`robot-bundle-share-button__input--theme-${theme}`],
              'flex-1 rounded-none rounded-l h-full bg-transparent',
              theme === 'light' && 'text-white border-white',
              theme === 'dark' && 'text-black border-black'
            )}
            onFocus={(event) => event.target.select()}
            data-testid="share-link-input"
            aria-describedby="button-generate"
            readOnly
            value={shareLinkUrl && getShareLinkUrl(shareLinkUrl)}
          />

          <button
            className={classNames(
              'uppercase font-bold rounded-r rounded-l-none h-full px-2 py-1',
              'font-medium border border-solid hover:bg-gray-100 transition-colors duration-200 ease-in-out',
              theme === 'light' && 'border-white text-white hover:bg-brand-darkPurple',
              theme === 'dark' && 'border-black text-black '
            )}
            type="button"
            onClick={() => {
              if (!shareLinkUrl) return;
              copyToClipboard(getShareLinkUrl(shareLinkUrl));
              onShareLinkCopied?.();
              setLinkCopied(true);
            }}
          >
            {linkCopied ? t('copyShareLinkButtonCopiedText') : t('copyShareLinkButtonText')}
          </button>
        </div>
      ) : shareLinkMutation.isLoading ? (
        <Skeleton height={26} containerTestId="loading-skeleton" />
      ) : (
        <button
          id="myrobot-share"
          className={classNames(
            styles['robot-bundle-share-button__share-btn'],
            'border block border-solid font-medium w-full rounded py-3 btn-lg',
            theme === 'light' && 'border-white text-white hover:bg-brand-darkPurple',
            theme === 'dark' && 'btn-outline-light',
            {
              'text-white': theme === 'light' || linkCopied,
              'text-black': theme === 'dark' && !linkCopied
            }
          )}
          type="button"
          disabled={shareLinkMutation.isLoading}
          onClick={() => shareLinkMutation.mutate()}
        >
          {t('shareLinkButtonText')}
        </button>
      )}
    </div>
  );
};

export default RobotBundleShareButton;
