import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

const RobotBundlePrefilled = ({ text, icon }) => {
  return (
    <div
      className={classNames(
        styles['robot-bundle-prefilled'],
        styles['robot-bundle-prefilled'],
        'flex align-content-stretch'
      )}
    >
      <div className={classNames(styles['robot-bundle-prefilled__left-box'], 'flex flex-shrink-0')}>
        <div
          className={classNames(
            styles['robot-bundle-prefilled__thumbnail'],
            'items-center justify-center flex'
          )}
        >
          <span className={styles['check']}>{icon}</span>
        </div>
      </div>
      <div className={classNames(styles['robot-bundle-prefilled__right-box'], 'p-3')}>
        <b>{text}</b>
      </div>
    </div>
  );
};

RobotBundlePrefilled.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired
};

export default RobotBundlePrefilled;
